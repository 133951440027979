import React from "react"
import { graphql } from "gatsby"
import componentGenerator from "@utils/componentGenerator"
import { Layout } from "@components/Layout"
import { MainAnimation } from "@components/Home"
import Seo from "../components/Seo/Seo"

export default function Home({ data }) {
  const { contentfulPage } = data
  let components = contentfulPage?.components
  const heroComponent = components[0]
  const seo = contentfulPage?.pageSeo
  const simpleHeader = contentfulPage?.simpleHeader
  const simpleHeaderCta = contentfulPage?.simpleHeaderCta
  const simpleFooter = contentfulPage?.simpleFooter

  return (
    <Layout
      headerTheme={{ theme: "dark", background: "transparent" }}
      simpleHeader={simpleHeader}
      simpleFooter={simpleFooter}
      simpleHeaderCta={simpleHeaderCta}
    >
      <Seo
        title={seo?.seoTitle}
        description={seo?.seoDescription}
        noIndex={seo?.noIndex}
        canonical={seo?.canonical}
        image={seo?.graphImage?.file?.url}
        imageAlt={seo?.graphImage?.description}
        metaTags={seo?.metaTags}
        article={seo?.article}
      />
      <MainAnimation component={heroComponent} />
      <div>{componentGenerator("/", components.slice(1))}</div>
    </Layout>
  )
}

export const homepageQuery = graphql`
  query homePageQuery {
    contentfulPage(internalName: { eq: "Homepage" }) {
      id
      __typename
      slug
      id
      simpleHeader
      simpleHeaderCta {
        id
        internalName
        internalLink
        label
        link
        variant
      }
      simpleFooter
      pageSeo {
        graphImage {
          description
          title
          file {
            url
          }
        }
        seoTitle
        seoDescription
        canonical
        noIndex
      }
      components {
        ... on ContentfulComponentHeading {
          __typename
          id
          internalName
          backgroundColor
          kicker
          headingSize
          heading
          noTopPadding
          subheading {
            subheading
          }
          callsToAction {
            contentful_id
            id
            internalLink
            label
            type
            link
            variant
          }
        }
        ... on ContentfulComponentSlidebox {
          __typename
          id
          internalName
          noTopPadding
          noBottomPadding
          slideboxHeading: heading {
            id
            internalName
            backgroundColor
            kicker
            headingSize
            heading
            subheading {
              subheading
            }
            callsToAction {
              contentful_id
              id
              internalLink
              label
              type
              link
              variant
            }
          }
          slides {
            ... on ContentfulComponentGeneric {
              id
              align
              background
              navTitle
              iconIdSelect
              callsToAction {
                variant
                type
                link
                label
                id
                internalName
                internalLink
                contentful_id
              }
              kicker
              heading
              headingSize
              subheading {
                raw
              }
              bodyCopy {
                raw
              }
              featuredImage {
                gatsbyImageData
                file {
                  url
                }
                title
                description
                contentful_id
              }
              lottie {
                file {
                  url
                }
              }
              video {
                videoUrl
                thumbnail {
                  gatsbyImageData
                  url
                  title
                  description
                }
                autoplay
                videoFile {
                  title
                  description
                  file {
                    url
                  }
                }
              }
            }
          }
        }
        ... on ContentfulComponentConversionPanel {
          kicker
          __typename
          id
          textAlign
          alignContent
          background
          subheading {
            raw
          }
          callsToAction {
            contentful_id
            id
            internalLink
            label
            type
            link
            variant
          }
          headingSize
          heading
          type
          reference {
            ... on ContentfulComponentTrustbar {
              __typename
              id
              heading
              companies {
                id
                name
                logo {
                  gatsbyImageData
                  file {
                    url
                  }
                  title
                  description
                }
              }
            }
            ... on ContentfulComponentTestimonial {
              __typename
              id
              author {
                id
                fullName
                role
                headshot {
                  gatsbyImageData
                  file {
                    url
                  }
                  title
                  description
                }
                company {
                  id
                  name
                }
              }
              quote {
                quote
              }
            }
            ... on ContentfulComponentImage {
              __typename
              id
              primaryImage {
                file {
                  url
                }
                description
              }
            }
          }
        }
        ... on ContentfulComponentVideoSection {
          __typename
          contentful_id
          type
          background
          kicker
          noTopPadding
          subheadingText {
            subheadingText
          }
          videoHeading
          internalName
          javascriptSnippet {
            javascriptSnippet
          }
          htmlSnippet {
            htmlSnippet
          }
          featuredVideo {
            videoUrl
            thumbnail {
              gatsbyImageData
              url
              title
              description
            }
            videoFile {
              title
              description
              gatsbyImageData
              file {
                url
              }
            }
          }
        }
        ... on ContentfulComponentHero {
          __typename
          id
          type
          background
          alignImage
          mobileTextAlign
          subheading {
            raw
          }
          kicker
          heading
          breadcrumbs
          breadcrumbTitle
          headingSize
          breadcrumbs
          categories {
            title
            backgroundColor
          }
          callsToAction {
            contentful_id
            id
            internalLink
            label
            type
            link
            variant
          }
          featuredImage {
            gatsbyImageData
            file {
              url
              contentType
            }
            title
            description
          }
          reference {
            ... on ContentfulComponentImage {
              id
              primaryImage {
                gatsbyImageData
                file {
                  url
                }
                title
                description
              }
            }
          }
        }
        ... on ContentfulComponentIconBlock {
          __typename
          contentful_id
          internalName
          heading
          kicker
          type
          background
          noTopPadding
          noBottomPadding
          unrestrictImageHeight
          backgroundImage {
            id
            primaryImage {
              gatsbyImageData
              file {
                url
              }
              title
              description
            }
          }
          subheading {
            subheading
          }
          callsToAction {
            contentful_id
            id
            internalLink
            label
            type
            link
            variant
          }
          icons {
            id
            alignCaption
            caption {
              raw
            }
            description
            internalLink
            internalName
            primaryImage {
              gatsbyImageData
              file {
                url
              }
              title
              description
            }
            title
            url
            featureGridTitle
            featureGridDescription
          }
          featuredPersona {
            ... on ContentfulComponentGeneric {
              id
              align
              background
              callsToAction {
                variant
                type
                link
                label
                id
                internalName
                internalLink
                contentful_id
              }
              kicker
              heading
              headingSize
              subheading {
                raw
              }
              bodyCopy {
                raw
              }
              featuredImage {
                gatsbyImageData
                file {
                  url
                }
                title
                description
                contentful_id
              }
            }
          }
        }
        ... on ContentfulComponentSwitchback {
          __typename
          id
          type
          alignImage
          subheading {
            raw
          }
          noTopPadding
          noBottomPadding
          subheadingBullet
          kicker
          heading
          headingSize
          callsToAction {
            contentful_id
            id
            internalLink
            label
            type
            link
            variant
          }
          animation1Kicker
          animation1Heading
          animation1 {
            raw
          }
          animation1File {
            file {
              url
            }
          }
          animation2File {
            file {
              url
            }
          }
          animation3File {
            file {
              url
            }
          }
          animation2Kicker
          animation2Heading
          animation2 {
            raw
          }
          animation3Kicker
          animation3Heading
          animation3 {
            raw
          }
          featuredImage {
            gatsbyImageData
            file {
              url
              contentType
            }
            title
            description
          }
          videoUrl
        }
        ... on ContentfulComponentTrustbar {
          __typename
          id
          companies {
            logo {
              gatsbyImageData
              file {
                url
              }
              title
              description
            }
          }
          noTopPadding
          noBottomPadding
          background
          heading
          id
          type
          internalName
          contentful_id
        }
        ... on ContentfulComponentCarousel {
          __typename
          id
          type
          background
          backgroundImage {
            primaryImage {
              gatsbyImageData
              file {
                url
              }
            }
            title
            description
          }
          subheading {
            subheading
          }
          subheadingTest {
            raw
            references {
              internalLink
              link
              label
              contentful_id
              sys {
                contentType {
                  sys {
                    type
                    id
                  }
                }
              }
            }
          }
          kicker
          heading
          headingSize
          autoplay
          noTopPadding
          noBottomPadding
          carouselItems {
            ... on ContentfulComponentGeneric {
              id
              align
              background
              callsToAction {
                variant
                type
                link
                label
                id
                internalName
                internalLink
                contentful_id
              }
              heading
              headingSize
              subheading {
                raw
              }
              icon {
                gatsbyImageData
                file {
                  url
                }
                title
                description
                contentful_id
              }
              featuredImage {
                gatsbyImageData
                file {
                  url
                }
                title
                description
                contentful_id
              }
            }
          }
        }
        ... on ContentfulComponentFaqAccordion {
          __typename
          contentful_id
          subhead
          internalName
          heading
          noTopPadding
          noBottomPadding
          faQs {
            answer {
              raw
            }
            question
          }
        }
        ... on ContentfulComponentTileSection {
          __typename
          id
          internalName
          kicker
          heading
          headingSize
          noTopPadding
          noBottomPadding
          pagination
          desktopPageSize
          tabletPageSize
          mobilePageSize
          tileType
          subheading {
            raw
          }
          tiles {
            id
            internalName
            kicker
            heading
            headingSize
            subheading
            description {
              description
            }
            category {
              title
            }
            integrations {
              title
            }
            useCases {
              title
            }
            userGroups {
              title
            }
            ctaLabel
            ctaLink
            ctaVariant
            ctaInternalLink
            videoLink
            videoCaption {
              raw
            }
            icon {
              gatsbyImageData
              file {
                url
              }
              title
              description
            }
          }
        }
      }
    }
  }
`
